import React from 'react';

import Bookings from './bookings';

import { AppContextProvider } from '../../../context/AppContext';

const AdminBookingsPage = () => {
	return (
		<AppContextProvider>
			<Bookings />
		</AppContextProvider>
	);
};

export default AdminBookingsPage;

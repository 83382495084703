import React, { useState, useMemo, useContext } from 'react';
import { navigate } from 'gatsby';
import { DataStore } from '@aws-amplify/datastore';
import { Auth } from 'aws-amplify';
import _format from 'date-fns/format';
import _groupBy from 'lodash/groupBy';
import {
	Paper,
	Typography,
	Container,
	Tabs,
	Tab,
	CircularProgress,
	Box,
} from '@mui/material';

import BookingListPopup from './BookingListPopup';

import PrivateRoute from '../../../components/PrivateRoute';

import { AppContext } from '../../../context/AppContext';

import BackgroundImage from '../../../images/background.jpg';
import BookingsCalendar from '../../../components/BookingsCalendar/BookingsCalendar';

import { logout } from '../../../utils/auth';

import { bookingLocations } from '../../../constants/bookingLocations';

const Bookings = () => {
	const [currentTab, setCurrentTab] = useState(bookingLocations[0].value);
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { isLoading, bookings, getBookingsByDate } = useContext(AppContext);

	const bookingsByDate = useMemo(
		() => getBookingsByDate(currentTab),
		[bookings, currentTab]
	);

	const onDateSelect = (date: Date) => {
		const formattedDate = _format(date, 'yyyy-MM-dd');
		setSelectedDate(date);

		if (isModalVisible || !bookingsByDate[formattedDate]?.length) return;

		setIsModalVisible(true);
	};

	const onCloseModal = () => {
		setIsModalVisible(false);
	};

	const signOut = async () => {
		try {
			await DataStore.clear();
			await Auth.signOut();

			logout(() => {
				navigate('/admin');
			});
		} catch (error) {
			console.log('error signing out: ', error);
		}
	};

	return (
		<div
			style={{
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
				minHeight: 'calc(100vh - 120px)',
			}}
		>
			<PrivateRoute>
				<Container maxWidth='lg' sx={{ padding: 3 }}>
					<Paper elevation={3} sx={{ padding: 3 }}>
						<Typography
							variant='h5'
							sx={{ marginBottom: 3, textAlign: 'center' }}
						>
							Reservas pendentes
						</Typography>

						<Tabs
							value={currentTab}
							onChange={(_, value) => setCurrentTab(value)}
							sx={{ marginBottom: 2 }}
							variant='scrollable'
						>
							{bookingLocations.map(location => (
								<Tab
									label={
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											{location.name}{' '}
											{isLoading && <CircularProgress size={16} />}
										</Box>
									}
									value={location.value}
									key={location.value}
								/>
							))}
						</Tabs>

						<BookingsCalendar
							onChange={onDateSelect}
							bookings={bookings[currentTab]}
						/>

						<Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
							<Typography
								variant='body2'
								onClick={signOut}
								sx={{ cursor: 'pointer' }}
							>
								Sair da conta
							</Typography>
						</Box>
					</Paper>
				</Container>
			</PrivateRoute>

			<BookingListPopup
				isModalVisible={isModalVisible}
				onCloseModal={onCloseModal}
				selectedDate={selectedDate}
				bookings={bookingsByDate[_format(selectedDate, 'yyyy-MM-dd')]}
			/>
		</div>
	);
};

export default Bookings;
